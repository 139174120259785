import { render, staticRenderFns } from "./productGalaxy.vue?vue&type=template&id=f84f5bfa&scoped=true"
import script from "./productGalaxy.vue?vue&type=script&lang=js"
export * from "./productGalaxy.vue?vue&type=script&lang=js"
import style0 from "./productGalaxy.vue?vue&type=style&index=0&id=f84f5bfa&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6_ckru7f3yypsoan6edyu23zfbiq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f84f5bfa",
  null
  
)

export default component.exports